export const getTheme = () => {
  let theme = "";
  let today = new Date().getHours();
  // If current time is between 7AM to 7PM
  if (today >= 7 && today <= 18) {
    theme = "light";
  } else {
    theme = "dark";
  }
  return theme;
};

// export const setWithExpiry = (key, value, ttl) => {
//   const now = new Date();
  
//   // `item` is an object which contains the original value
//   // as well as the time when it's supposed to expire
//   const item = {
//     value: value,
//     expiry: now.getTime() + ttl,
//   };
//   localStorage.setItem(key, JSON.stringify(item));
// };

// export const getWithExpiry = (key) => {
//   const itemStr = localStorage.getItem(key);
//   // if the item doesn't exist, return null
//   if (!itemStr) {
//     return null;
//   }
//   const item = JSON.parse(itemStr);
//   const now = new Date();
//   // compare the expiry time of the item with the current time
//   if (now.getTime() > item.expiry) {
//     // If the item is expired, delete the item from storage
//     // and return null
//     localStorage.removeItem(key);
//     return null;
//   }
//   return item.value;
// };
