import React, { lazy } from "react";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import Notification from "../components/Notification";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { CONFIG } from "../constants/configuration";
import alpsIcon from '../assets/icons/A_logo.png';
import bmsIcon from '../assets/icons/Celgene_cassa.jpg'

// APP ROUTE COMPONENTS
const Dashboard = lazy(() => import("./dashboard"));
const Account = lazy(() => import("./user"));
const Welcome = lazy(() => import("../app/dashboard/home/welcome"));

//The routes comprises of contents

const App = () => {
  const match = useRouteMatch();
  const showLoading = useSelector((state) => state.commonReducer.showLoading);

  let imgPath = document.getElementById("favIcon")
  let appTitle = document.getElementById("app_title")
  if (CONFIG.product === "RL") {
    imgPath.href = alpsIcon
    appTitle.innerHTML = "Alps Auth"
  }
  else if (CONFIG.product === "BMS") {
    imgPath.href = bmsIcon
    appTitle.innerHTML = "Auth"
  }

  return (
    <>
      <Loading show={showLoading} />
      <Notification />
      <div style={{ opacity: showLoading ? 0.5 : 1 }}>
        <SuspenseWrapper>
          <Switch>
            <Route path={`${match.url}dashboard`} key="dashboard">
              <Dashboard />
            </Route>
            <Route path={`${match.url}user`} key="user">
              <Account />
            </Route>

            <Route path="/saml/SSO" key="sso">
              <Welcome />
            </Route>
            {/* <Route path="/" key="login" component={() => { window.location = 'https://sso-qa.bms.com/auth/saml2/jsp/idpSSOInit.jsp?metaAlias=/BMS/urn:bms:idp:qa:115:50&spEntityID=https://cassa3-0.web-uat.bms.com/saml/metadata'; 
            return null; }} /> */}
            <Route exact path="/" key="login">
              <Redirect to={"/user/login"} />
            </Route>
            <Route path="*" key="catch-all">
              <Redirect to="/" />
            </Route>
          </Switch>
        </SuspenseWrapper>
      </div>
    </>
  );
};

export default App;
